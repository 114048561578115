// for the list of posts

.section__title {
	font-size: $section__title-font-size;
        font-family: $header-font-family;
}

.post-list__item {
	margin-bottom: 3em;
}

.item__title--big {
	display: block;
	font-size: $item__title-big-font-size;
	font-family: $header-font-family;
	font-weight: 700;
	line-height: 1.25;
}

.item__title--small {
	font-size: 1rem;
        font-family: $header-font-family;
}

.item__date {
	color: $item__date-color;
	display: block;
	font-size: $item__date-font-size;
	margin-bottom: .2rem;
	margin-top: .2rem;
}
